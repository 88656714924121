import React, { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Loader, Button } from '@wix/design-system';

import useReplyToReview from '../useReplyToReview';
import ReplyInputArea from '../ReplyInputArea';

type ReplyFormProps = {
  replyUtils: ReturnType<typeof useReplyToReview>;
  onClose: () => void;
  autoFocus?: boolean;
};

const ReplyForm: FC<ReplyFormProps> = ({
  onClose,
  replyUtils: { state, setState, writeReplyToReviewAndUpdateReviews },
  autoFocus,
}) => {
  const { t } = useTranslation();
  const isInProcess = state.isInProcess;

  return (
    <Box
      dataHook="write-reply-review-form"
      display="block"
      width="100%"
      marginLeft="SP2"
    >
      <ReplyInputArea
        replyState={state}
        setState={setState}
        autoFocus={autoFocus}
      />
      <Box marginTop="SP3">
        <Box marginRight="SP2" marginLeft="auto">
          <Button
            dataHook="write-reply-review-cancel-button"
            size="small"
            priority="secondary"
            onClick={onClose}
          >
            {t('profile-page-review-write-reply-cancel-button')}
          </Button>
        </Box>
        <Button
          dataHook="write-reply-review-submit-button"
          size="small"
          onClick={writeReplyToReviewAndUpdateReviews}
          disabled={isInProcess}
        >
          {isInProcess ? (
            <Loader size="tiny" />
          ) : (
            t('profile-page-review-write-reply-submit-button')
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default ReplyForm;
