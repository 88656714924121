import { Box, Divider } from '@wix/design-system';
import { FC } from 'react';
import s from './VerticalDivider.module.scss';

interface Props {
  className?: string;
}

const VerticalDivider: FC<Props> = ({ className = s.verticalDivider }) => {
  return (
    <Box height="16px" className={className}>
      <Divider direction="vertical" />
    </Box>
  );
};

export default VerticalDivider;
