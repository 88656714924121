import React, { FC } from 'react';
import { Box } from '@wix/design-system';

type Props = {
  error: any;
};

const ErrorFallback: FC<Props> = ({ error }) => {
  return (
    <Box height="100%" direction="vertical">
      <div role="alert" style={{ margin: '42px 30px' }}>
        <p>Something went wrong:</p>
        <pre style={{ color: 'red' }}>{error.message}</pre>
      </div>
    </Box>
  );
};

export default ErrorFallback;
