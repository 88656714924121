import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, TextButton } from '@wix/design-system';
import { ArrowDownRightSmall } from '@wix/wix-ui-icons-common';
import { IHandleBIGenericClick, ReviewData } from '@common/types';
import useReplyToReview from './useReplyToReview';
import ReviewReplyModal from '@components/Template/TemplateReviews/ReviewReplyModal/ReviewReplyModal';
import ReplyForm from './ReplyForm';
import ReplyReview from '../ReplyReview';
import ReplyActionButtons from '../ReviewReplyDialogue/ReplyActionButtons';
import DeleteReviewReplyModal from '../DeleteReviewReplyModal';

export const writeReplyReviewDataHooks = {
  container: 'write-reply-review-container',
  replyLink: 'write-reply-review-link',
};

export type WriteReplyToReview = (
  id: string,
  replyBody: string,
  revision: Long.Long | undefined,
) => Promise<void>;

export type IDeleteReply = (reviewId: string) => Promise<void>;

export interface IWriteReplyToReviewProps {
  review: ReviewData;
  writeReplyToReview: WriteReplyToReview;
  isMobileView?: boolean;
  isUsingMobileModal?: boolean;
  handleBiGenericClick?: IHandleBIGenericClick;
  hasActionMenu?: boolean;
  deleteReply?: IDeleteReply;
}

const WriteReplyReview = ({
  review,
  writeReplyToReview,
  deleteReply,
  isMobileView,
  isUsingMobileModal,
  handleBiGenericClick,
  hasActionMenu,
}: IWriteReplyToReviewProps) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isShowingDeleteModal, setIsShowingDeleteModal] =
    useState<boolean>(false);
  const [focusOnReview, setFocusOnReview] = useState<boolean>(false);
  const isMobileModalOpen =
    isMobileView && isUsingMobileModal && (showForm || focusOnReview);
  const onClose = useCallback(() => setShowForm(false), []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setFocusOnReview(params?.get('reviewId') === review.id);
  }, []);

  const replyUtils = useReplyToReview({
    writeReplyToReview,
    review,
    onClose,
  });

  const reply = review.reply;

  if (reply && !showForm) {
    return (
      <Box dataHook={writeReplyReviewDataHooks.container}>
        <ReplyReview
          reply={reply}
          replyTitleKey={'marketplace.template-page.reply-title'}
          actionsNode={
            hasActionMenu ? (
              <ReplyActionButtons
                isMobile={isMobileView}
                editOnClick={() => setShowForm(true)}
                deleteOnClick={() => setIsShowingDeleteModal(true)}
              />
            ) : null
          }
        />
        <DeleteReviewReplyModal
          isOpen={isShowingDeleteModal}
          onDelete={() => {
            deleteReply?.(review.id!);
            setIsShowingDeleteModal(false);
          }}
          onClose={() => setIsShowingDeleteModal(false)}
          isMobile={isMobileView}
        />
      </Box>
    );
  }

  if (!showForm && !focusOnReview) {
    return (
      <WriteReplyReviewContainer>
        <ShowReplyFormButton
          onClick={() => {
            handleBiGenericClick &&
              handleBiGenericClick({ clickType: 'Reply on review' });
            setShowForm(true);
          }}
        />
      </WriteReplyReviewContainer>
    );
  }

  if (isMobileModalOpen) {
    return (
      <WriteReplyReviewContainer>
        <ReviewReplyModal
          review={review}
          isOpen={showForm || focusOnReview}
          onClose={() => {
            handleBiGenericClick &&
              handleBiGenericClick({ clickType: 'Close reply on review' });
            onClose();
          }}
          onCancel={() => {
            handleBiGenericClick &&
              handleBiGenericClick({ clickType: 'Cancel reply on review' });
            onClose();
          }}
          replyUtils={replyUtils}
        />
      </WriteReplyReviewContainer>
    );
  }

  return (
    <WriteReplyReviewContainer>
      <ReplyForm
        replyUtils={replyUtils}
        onClose={() => {
          handleBiGenericClick &&
            handleBiGenericClick({ clickType: 'Cancel reply on review' });
          onClose();
        }}
        autoFocus={focusOnReview}
      />
    </WriteReplyReviewContainer>
  );
};

const WriteReplyReviewContainer: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  return (
    <Box width="100%" dataHook={writeReplyReviewDataHooks.container}>
      <Box width="SP3">
        <ArrowDownRightSmall />
      </Box>
      {children}
    </Box>
  );
};

const ShowReplyFormButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Box display="block" marginLeft="SP2">
      <Box display="block" marginBottom="SP1">
        <TextButton
          skin="dark"
          dataHook={writeReplyReviewDataHooks.replyLink}
          underline="always"
          size="small"
          weight="thin"
          onClick={onClick}
        >
          {t('profile-page-review-write-reply-link')}
        </TextButton>
      </Box>
    </Box>
  );
};

export default WriteReplyReview;
