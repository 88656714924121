import React, { FC, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import {
  Box,
  Text,
  Divider,
  StatusIndicator,
  TextButton,
} from '@wix/design-system';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntersect } from '@wix/experts-common';
import ErrorFallback from '../ErrorFallback';
import { IHandleBIGenericClick, ReviewData } from '../../common/types';
import Review from './Review';
import ReviewsHeader from './ReviewsHeader';
import RenderLoadingReview from '@components/Reviews/RenderLoadingReview';
import { ReplyProps } from './ReviewReplyDialogue/ReviewReplyDialogue';

export type ReviewsTextKeys = Record<
  'viewReviewSubjectCTA' | 'replyAuthorTitle' | 'noReviews',
  string
>;

export type ReviewsProps = {
  showReviewsCount?: boolean;
  reviewsCount: number;
  averageReviewRating: number;
  reviews: ReviewData[];
  cursor?: string;
  i18nKeys: ReviewsTextKeys;
  services?: any[];
  isMyProfile?: boolean;
  fetchMoreOnScroll?: boolean;
  borderContainerClass?: string;
  marginBottom?: string;
  isMobileView: boolean;
  fetchNextReviews: () => Promise<void>;
  handleBiGenericClick?: IHandleBIGenericClick;
  loadNewReview?: boolean;
  addNewReviewBanner?: ReactNode;
  replyProps: ReplyProps;
  onDelete?: () => void;
  onEdit?: () => void;
  userReview?: ReviewData | null;
};

const Reviews: FC<ReviewsProps> = ({
  showReviewsCount = true,
  reviewsCount,
  averageReviewRating,
  reviews,
  cursor,
  isMyProfile,
  services,
  fetchMoreOnScroll = true,
  borderContainerClass,
  marginBottom,
  isMobileView,
  fetchNextReviews,
  handleBiGenericClick,
  i18nKeys,
  loadNewReview,
  addNewReviewBanner,
  replyProps,
  onDelete,
  onEdit,
  userReview,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ref, entry] = useIntersect({ rootMargin: '0px' }) as any;

  useEffect(() => {
    if (
      !isLoading &&
      entry.intersectionRatio === 1 &&
      cursor &&
      fetchMoreOnScroll
    ) {
      setIsLoading(true);
      fetchNextReviews()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [entry, isLoading, cursor, fetchNextReviews]);

  return (
    <Box display="block" dataHook="reviews-section" width="100%">
      <ReviewsHeader
        marginBottom={marginBottom}
        showReviewsCount={showReviewsCount}
        isMobileView={isMobileView}
        reviewsCount={reviewsCount}
        averageReviewRating={averageReviewRating}
      />
      {addNewReviewBanner && <Box marginTop="24px">{addNewReviewBanner}</Box>}
      {reviews?.length > 0 ? (
        <>
          {isMobileView && showReviewsCount && <Divider />}
          {loadNewReview && <RenderLoadingReview />}
          <Box
            dataHook="reviews-container"
            display="block"
            className={borderContainerClass}
          >
            {reviews.map((review: ReviewData, index: number) => (
              <PaddedReview
                key={review.id}
                review={review}
                isMyProfile={isMyProfile}
                services={services}
                handleBiGenericClick={handleBiGenericClick}
                showDivider={index + 1 < reviews.length}
                i18nKeys={i18nKeys}
                isMobileView={isMobileView}
                replyProps={replyProps}
                onEdit={onEdit}
                userReview={userReview}
              />
            ))}
            {!fetchMoreOnScroll && reviewsCount > reviews.length && (
              <ShowMoreButton fetchNextReviews={fetchNextReviews} />
            )}
          </Box>
          {cursor && fetchMoreOnScroll && (
            <div ref={ref} data-hook="infiniteScroll-container">
              <Box marginTop="26px" align="center" verticalAlign="middle">
                <StatusIndicator status="loading" />
              </Box>
            </div>
          )}
        </>
      ) : loadNewReview ? (
        <RenderLoadingReview hideDivider={true} />
      ) : (
        <NoReviews
          hasNegativeMargin={Boolean(borderContainerClass)}
          noReviewsKey={i18nKeys.noReviews}
          isMobileView={isMobileView}
        />
      )}
    </Box>
  );
};

const PaddedReview: FC<
  Pick<
    ReviewsProps,
    | 'isMyProfile'
    | 'services'
    | 'handleBiGenericClick'
    | 'i18nKeys'
    | 'isMobileView'
    | 'replyProps'
    | 'onEdit'
    | 'userReview'
  > & {
    review: ReviewData;
    showDivider: boolean;
  }
> = ({
  review,
  isMyProfile,
  services,
  handleBiGenericClick,
  showDivider,
  i18nKeys,
  isMobileView,
  replyProps,
  onEdit,
  userReview,
}) => {
  return (
    <Box display="block" paddingTop="SP4">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Review
          i18nKeys={i18nKeys}
          review={review}
          isMyProfile={isMyProfile}
          services={services}
          handleBiGenericClick={handleBiGenericClick}
          isMobileView={isMobileView}
          replyProps={replyProps}
          onEdit={onEdit}
          userReview={userReview}
        />
        {showDivider ? (
          <Box display="block" paddingTop="SP4">
            <Divider />
          </Box>
        ) : (
          <Box display="block" paddingTop="SP4"></Box>
        )}
      </ErrorBoundary>
    </Box>
  );
};

const ShowMoreButton: FC<Pick<ReviewsProps, 'fetchNextReviews'>> = ({
  fetchNextReviews,
}) => {
  const { t } = useTranslation();
  return (
    <Box paddingTop="30px" paddingBottom="42px">
      <TextButton underline="always" skin="dark" onClick={fetchNextReviews}>
        {t('show-more-text-button')}
      </TextButton>
    </Box>
  );
};

const NoReviews: FC<{
  hasNegativeMargin: boolean;
  noReviewsKey: string;
  isMobileView?: boolean;
}> = ({ hasNegativeMargin, noReviewsKey, isMobileView }) => {
  const { t } = useTranslation();
  return (
    <Box
      marginTop={hasNegativeMargin ? '-18px' : '18px'}
      paddingBottom={isMobileView ? '30px' : '0'}
    >
      <Text size="small" weight="thin" dataHook="no-reviews-text">
        {t(noReviewsKey)}
      </Text>
    </Box>
  );
};

export default Reviews;
