import React, { FC } from 'react';
import { Box, Text } from '@wix/design-system';
import StarBarAndRating from '@components/Reviews/Review/StarsRatingBar';
import _ from 'lodash';
import { ReviewData } from '@common/types';
import { useTranslation } from '@wix/wix-i18n-config';
import ShowMoreText from '@components/ShowMoreText';
import { MAX_CHARS_IN_REVIEW } from '@utils/constants';
import s from './ReviewSummaryCard.module.scss';

const ReviewSummaryCard: FC<{ review: ReviewData }> = ({ review }) => {
  const { t } = useTranslation();
  const overallScore = review?.overallScore || review?.score?.average || 0;

  return (
    <Box direction="vertical" className={s.reviewCardContainer}>
      <Text size="small" weight="bold">
        {t('marketplace.template-page.review-card.buyer-title')}
      </Text>
      <Box direction="vertical">
        {!_.isNil(review?.overallScore || review?.score?.average) && (
          <StarBarAndRating
            overallScore={overallScore}
            showNumericalRating={false}
          />
        )}
        <ShowMoreText
          dataHookPrefix="review-"
          originalText={review?.text}
          maxChars={MAX_CHARS_IN_REVIEW}
          handleShowMoreClick={() => null}
        />
      </Box>
    </Box>
  );
};

export default ReviewSummaryCard;
