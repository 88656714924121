import React, { FC } from 'react';

interface Props {
  className?: string;
}

const Star: FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25008 13.5L3.42001 10.7069C3.11848 10.3923 3.26355 9.94806 3.69975 9.85614L7.55008 9.3L9.54352 5.46172C9.61988 5.31468 9.80099 5.25739 9.94803 5.33375C10.007 5.3644 10.0542 5.41378 10.0821 5.47414L11.8501 9.3L15.7871 9.85594C16.0682 9.85594 16.4962 10.3406 16.0682 10.7069L13.2501 13.5L14.2388 17.568C14.2976 17.8104 14.0462 18.0979 13.7202 17.9578L9.75009 16L5.78308 17.9502C5.56276 18.0917 5.17188 17.8757 5.25008 17.5483L6.25008 13.5Z"
        fill="#FFC23D"
      />
    </svg>
  );
};

export default Star;
