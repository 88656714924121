import { FC } from 'react';
import { TextButton, Box, PopoverMenu, IconButton } from '@wix/design-system';
import { useTranslation } from '@wix/fe-essentials-standalone';
import VerticalDivider from '@components/VerticalDivider';
import { More } from '@wix/wix-ui-icons-common';

const dataHooks = {
  replyActionButtonsContainer: 'reply-action-buttons-container',
  editButton: 'reply-action-buttons-edit',
  deleteButton: 'reply-action-buttons-delete',
};

const ReplyActionButtons: FC<{
  isMobile?: boolean;
  editOnClick?: () => void;
  deleteOnClick?: () => void;
}> = ({ isMobile, editOnClick, deleteOnClick }) => {
  const { t } = useTranslation();
  const actions = [];
  if (editOnClick) {
    actions.push({
      dataHook: dataHooks.editButton,
      textKey: 'marketplace.review.reply.actions.edit',
      onClick: editOnClick,
    });
  }

  if (deleteOnClick) {
    actions.push({
      dataHook: dataHooks.deleteButton,
      textKey: 'marketplace.review.reply.actions.delete',
      onClick: deleteOnClick,
    });
  }

  if (isMobile) {
    return (
      <PopoverMenu
        dataHook={dataHooks.replyActionButtonsContainer}
        placement="bottom-end"
        showArrow={false}
        moveBy={{ y: 6 }}
        triggerElement={
          <IconButton priority="secondary" size="small">
            <More />
          </IconButton>
        }
      >
        {actions.map(({ onClick, textKey, dataHook }) => (
          <PopoverMenu.MenuItem
            dataHook={dataHook}
            key={textKey}
            text={t(`${textKey}-mobile`)}
            onClick={onClick}
          />
        ))}
      </PopoverMenu>
    );
  }

  return (
    <Box flexGrow={0} dataHook={dataHooks.replyActionButtonsContainer}>
      {actions.map(({ onClick, textKey }, index) => {
        return (
          <>
            <TextButton size="small" onClick={onClick}>
              {t(textKey)}
            </TextButton>
            {index < actions.length - 1 ? <VerticalDivider /> : null}
          </>
        );
      })}
    </Box>
  );
};

export default ReplyActionButtons;
