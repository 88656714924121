import React, { FC } from 'react';
import { Box, Text, IconButton, CloseButton } from '@wix/design-system';
import { ArrowLeft } from '@wix/wix-ui-icons-common';
import { useTranslation } from '@wix/wix-i18n-config';
import s from './MobileFullScreenModalLayout.module.scss';
import { useIntersectionObserver } from 'usehooks-ts';

type Props = {
  title: string;
  content: JSX.Element;
  dataHook?: string;
  onClose?: () => void;
  onBack?: () => void;
  footer: JSX.Element;
};

export const fullScreenModalDataHooks = {
  layoutDefault: 'mobile-filter-content',
  backButton: 'modal-back-btn',
  title: 'mobile-filter-modal-title',
  closeButton: 'close-filter-modal',
} as const;

const MobileFullScreenModalLayout: FC<Props> = ({
  title,
  content,
  dataHook,
  onClose,
  onBack,
  footer,
}) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    initialIsIntersecting: true,
  });
  return (
    <Box
      dataHook={dataHook || fullScreenModalDataHooks.layoutDefault}
      direction="vertical"
      className={s.modal}
    >
      <ModalHeader
        title={title}
        onClose={onClose}
        onBack={onBack}
        hasShadow={!isIntersecting}
      />
      <div className={s.modal__content}>
        <div ref={ref} />
        {content}
      </div>
      <div>{footer}</div>
    </Box>
  );
};

const ModalHeader: FC<
  Pick<Props, 'onBack' | 'title' | 'onClose'> & { hasShadow: boolean }
> = ({ onBack, title, onClose, hasShadow }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        boxShadow: hasShadow
          ? '0px 0px 6px 0px rgba(0, 6, 36, 0.12), 0px 2px 4px 0px rgba(0, 6, 36, 0.12)'
          : undefined,
      }}
    >
      <Box
        paddingLeft={onBack ? '14px' : '24px'}
        paddingRight="24px"
        paddingTop={onBack ? '15px' : '18px'}
        paddingBottom="12px"
        align="space-between"
      >
        <Box verticalAlign="middle">
          {onBack && (
            <Box paddingRight="12px">
              <IconButton
                skin="light"
                onClick={() => onBack()}
                dataHook={fullScreenModalDataHooks.backButton}
                ariaLabel={t('marketplace-aria-label-modal-back-btn')}
              >
                <ArrowLeft color="#000000" />
              </IconButton>
            </Box>
          )}
          <Text
            tabIndex={0}
            weight="bold"
            dataHook={fullScreenModalDataHooks.title}
          >
            {title}
          </Text>
        </Box>
        {onClose && (
          <CloseButton
            skin="dark"
            dataHook={fullScreenModalDataHooks.closeButton}
            size="large"
            onClick={() => onClose()}
          />
        )}
      </Box>
    </div>
  );
};

export default MobileFullScreenModalLayout;
