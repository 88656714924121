import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation, useHttpClient } from '@wix/fe-essentials-standalone';
import {
  Box,
  Text,
  Modal,
  Button,
  ModalMobileLayout,
  MessageModalLayout,
} from '@wix/design-system';
import { TEMPLATE_DELETE_REVIEW_API_URL } from '@utils/constants';
import {
  ACTIONS,
  useTemplateContext,
} from '@components/Contexts/TemplateContext';

const DeleteReviewModal: FC<{
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  onModalClose: () => void;
  isMobileView?: boolean;
  reviewId?: string;
}> = ({ modalOpen, onModalClose, isMobileView, reviewId, setModalOpen }) => {
  const { t } = useTranslation();
  const httpClient = useHttpClient();
  const { dispatch } = useTemplateContext();

  const deleteReview = async () => {
    let res = await httpClient.post(TEMPLATE_DELETE_REVIEW_API_URL, {
      reviewId,
    });
    const deletedReview = res.data;
    dispatch({
      type: ACTIONS.DELETE_REVIEW,
      payload: deletedReview,
    });
    setModalOpen(false);
  };

  return (
    <Modal
      isOpen={modalOpen}
      dataHook=""
      screen={isMobileView ? 'mobile' : 'desktop'}
    >
      {isMobileView ? (
        <ModalMobileLayout
          title={
            <Text weight="bold">
              {t('marketplace.template-page.review.delete-modal.title')}
            </Text>
          }
          content={
            <Text weight="thin" secondary>
              {t('marketplace.template-page.review.delete-modal.content')}
            </Text>
          }
          footer={
            <Box align="right">
              <Box marginRight="12px">
                <Button
                  skin="destructive"
                  priority="secondary"
                  onClick={onModalClose}
                >
                  {t('marketplace.template-page.review.delete-modal.cancel')}
                </Button>
              </Box>
              <Button skin="destructive" onClick={deleteReview}>
                {t('marketplace.template-page.review.delete-modal.cta')}
              </Button>
            </Box>
          }
          onCloseButtonClick={onModalClose}
        />
      ) : (
        <MessageModalLayout
          theme={'destructive'}
          onCloseButtonClick={onModalClose}
          secondaryButtonOnClick={onModalClose}
          primaryButtonText={t(
            'marketplace.template-page.review.delete-modal.cta',
          )}
          primaryButtonOnClick={deleteReview}
          secondaryButtonText={t(
            'marketplace.template-page.review.delete-modal.cancel',
          )}
          title={t('marketplace.template-page.review.delete-modal.title')}
          content={
            <Text>
              {t('marketplace.template-page.review.delete-modal.content')}
            </Text>
          }
        />
      )}
    </Modal>
  );
};

export default DeleteReviewModal;
