import { useTranslation } from '@wix/wix-i18n-config';
import { useCallback } from 'react';
import { DEFAULT_CURRENCY } from '@utils/constants';

interface Params {
  formatterOverrides?: Record<string, any>;
  currency: string;
  conversionRate?: number;
}

const roundStartingPrice = (price: number) => {
  const scaleExponent = Math.floor(Math.log10(price));
  const halfScaleExponent = Math.ceil(scaleExponent / 2);
  const halfScale = 10 ** halfScaleExponent;
  const roundedDescaledValue = Math.floor(price / halfScale);
  const roundedMaxPrice = roundedDescaledValue * halfScale;
  return roundedMaxPrice;
};

const useFormattedCurrency = ({
  currency = DEFAULT_CURRENCY,
  formatterOverrides,
  conversionRate,
}: Params) => {
  const {
    i18n: { language },
  } = useTranslation();

  const currencyFormatter = useCallback(
    (value: number) => {
      try {
        return Intl.NumberFormat(language, {
          style: 'currency',
          currencyDisplay: 'narrowSymbol',
          currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          ...formatterOverrides,
        }).format(value);
      } catch (err) {
        return Intl.NumberFormat(language, {
          style: 'currency',
          currencyDisplay: 'symbol',
          currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          ...formatterOverrides,
        }).format(value);
      }
    },
    [currency, formatterOverrides, language],
  );

  const startingPriceFormatter = useCallback(
    (price: number) => {
      const convertedPrice = conversionRate ? price * conversionRate : price;
      const roundedPrice =
        currency === DEFAULT_CURRENCY
          ? convertedPrice
          : roundStartingPrice(convertedPrice);
      return currencyFormatter(roundedPrice);
    },
    [currencyFormatter, currency, conversionRate],
  );

  const currencyConversionFormatter = useCallback(
    (price: number, roundUp = false) => {
      let convertedPrice = conversionRate ? price * conversionRate : price;
      convertedPrice = roundUp ? Math.ceil(convertedPrice) : convertedPrice;
      return currencyFormatter(convertedPrice);
    },
    [currencyFormatter, currency, conversionRate],
  );

  const symbol = currencyFormatter(0).replace(/\d/g, '').trim();
  return {
    currencyFormatter,
    currencyConversionFormatter,
    startingPriceFormatter,
    symbol,
  };
};

export default useFormattedCurrency;
