import React, { FC, ReactNode } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Text } from '@wix/design-system';
import { ArrowDownRightSmall } from '@wix/wix-ui-icons-common';
import { Reply } from '@wix/ambassador-marketplace-projects/types';
import ShowMoreText from '../../ShowMoreText';

export const replyReviewDataHooks = {
  container: 'reply-review-container',
};

const ReplyReview: FC<{
  reply?: Reply;
  replyTitleKey: string;
  actionsNode?: ReactNode;
}> = ({ reply, replyTitleKey, actionsNode }) => {
  const MAX_CHARS_IN_REPLY_REVIEW = 200;
  const { t } = useTranslation();

  if (!reply) {
    return <></>;
  }

  return (
    <Box width="100%" dataHook={replyReviewDataHooks.container} marginTop="SP4">
      <Box flexGrow={1}>
        <Box width="SP3">
          <ArrowDownRightSmall />
        </Box>
        <Box display="block" marginLeft="SP2">
          <Box display="block" marginBottom="SP1">
            <Text size="small" weight="normal" dataHook="reply-title">
              {t(replyTitleKey)}
            </Text>
          </Box>
          <ShowMoreText
            dataHookPrefix="reply-review-"
            originalText={reply?.text}
            maxChars={MAX_CHARS_IN_REPLY_REVIEW}
          />
        </Box>
      </Box>
      {actionsNode}
    </Box>
  );
};

export default ReplyReview;
