import React from 'react';
import { Box, Text, Image } from '@wix/design-system';
import _ from 'lodash';
import { useTranslation } from '@wix/wix-i18n-config';
import { useTemplateContext } from '@components/Contexts/TemplateContext';
import { fillImageUrl } from '@utils/urlUtils';

const TemplateSummary = () => {
  const {
    template: {
      media: { additionalImages },
      title,
      partnerProfileDetails: { businessName },
    },
  } = useTemplateContext();
  const { t } = useTranslation();

  const templateImage = additionalImages?.[0];

  return (
    <Box gap="18px" display="flex">
      <Box minWidth="54px">
        <Image
          width="54px"
          height="46px"
          borderRadius="6px"
          fit="cover"
          src={fillImageUrl(templateImage?.url!, 218, 144)}
          alt={templateImage?.description || ''}
        />
      </Box>
      <Box direction="vertical" gap="2px" verticalAlign="middle">
        <Text size="small" weight="normal">
          {title}
        </Text>
        <Text size="tiny" weight="thin">
          {t('marketplace.template-page.review-reply-modal.created-by', {
            name: businessName,
          })}
        </Text>
      </Box>
    </Box>
  );
};

export default TemplateSummary;
