import {
  MessageModalLayout,
  Modal,
  ModalMobileLayout,
  Text,
  Box,
  Button,
} from '@wix/design-system';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { FC } from 'react';

interface Props {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
  isMobile?: boolean;
}

const i18nKeys = {
  title: 'marketplace.review.reply.delete-reply-modal.title',
  content: 'marketplace.review.reply.delete-reply-modal.content',
  deleteButton: 'marketplace.review.reply.delete-reply-modal.delete',
  cancelButton: 'marketplace.review.reply.delete-reply-modal.cancel',
};

const DeleteReviewReplyModal: FC<Props> = ({
  isOpen,
  onDelete,
  onClose,
  isMobile,
}) => {
  const { t } = useTranslation();

  if (isMobile) {
    return (
      <Modal isOpen={isOpen}>
        <ModalMobileLayout
          title={<Text weight="bold">{t(i18nKeys.title)}</Text>}
          content={<Text weight="thin">{t(i18nKeys.content)}</Text>}
          footer={
            <Box align="right">
              <Box marginRight="12px">
                <Button
                  priority="secondary"
                  skin="destructive"
                  onClick={onClose}
                >
                  {t(i18nKeys.cancelButton)}
                </Button>
              </Box>
              <Button skin="destructive" onClick={onDelete}>
                {t(i18nKeys.deleteButton)}
              </Button>
            </Box>
          }
          onCloseButtonClick={onClose}
        />
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen}>
      <MessageModalLayout
        title={t(i18nKeys.title)}
        theme="destructive"
        primaryButtonText={t(i18nKeys.deleteButton)}
        primaryButtonOnClick={onDelete}
        secondaryButtonOnClick={onClose}
        secondaryButtonText={t(i18nKeys.cancelButton)}
        onCloseButtonClick={onClose}
        content={<Text>{t(i18nKeys.content)}</Text>}
      />
    </Modal>
  );
};

export default DeleteReviewReplyModal;
