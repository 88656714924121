import React, { FC } from 'react';
import { ScrollLink } from 'react-scroll';
import { Text } from '@wix/design-system';

interface Props {
  href: string;
  className: string;
  children: React.ReactNode;
  size?: 'small' | 'medium';
}

const LinkWithHref: FC<Props> = (props) => {
  return (
    <Text
      skin={props.className?.indexOf('active') > -1 ? 'primary' : 'standard'}
      {...props}
      size={props.size || 'small'}
      tagName="a"
      href={props.href}
      className={props.className}
    >
      {props.children}
    </Text>
  );
};

export default ScrollLink(LinkWithHref);
