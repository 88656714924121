import React, { FC } from 'react';
import {
  Box,
  Modal,
  Button,
  Divider,
  Loader,
  FormField,
} from '@wix/design-system';
import _ from 'lodash';
import { ReviewData } from '@common/types';
import { useTranslation } from '@wix/wix-i18n-config';
import MobileFullScreenModalLayout from '@components/MobileFullScreenModalLayout';
import { ReplyUtils } from '@components/Reviews/WriteReplyReview/useReplyToReview';
import ReplyInputArea from '@components/Reviews/WriteReplyReview/ReplyInputArea';
import ReviewSummaryCard from './ReviewSummaryCard';
import TemplateSummary from './TemplateSummary';

export const reviewReplyDataHooks = {
  modal: 'review-reply-modal',
  modalLayout: 'review-reply-modal-layout',
  submitButton: 'review-reply-modal-submit',
  cancelButton: 'review-reply-modal-cancel',
};

interface Props {
  isOpen: boolean;
  review: ReviewData;
  onClose: () => void;
  onCancel: () => void;
  replyUtils: ReplyUtils;
}

const ReviewReplyModal: FC<Props> = ({
  isOpen,
  onClose,
  onCancel,
  review,
  replyUtils: { state, setState, writeReplyToReviewAndUpdateReviews },
}) => {
  const { t } = useTranslation();

  const { isInProcess, replyBody, ...rest } = state;

  return (
    <Modal isOpen={isOpen} dataHook={reviewReplyDataHooks.modal}>
      <MobileFullScreenModalLayout
        title={t('marketplace.template-page.review-reply-modal.title')}
        content={
          <Box direction="vertical" gap="30px" padding="18px 24px">
            <TemplateSummary />
            <ReviewSummaryCard review={review} />
            <FormField
              label={t(
                'marketplace.template-page.review-reply-modal.response-label',
              )}
              required
              {...rest}
            >
              <ReplyInputArea
                replyState={{ replyBody }}
                setState={setState}
                inputAreaProps={{ resizable: false, autoFocus: true, rows: 4 }}
              />
            </FormField>
          </Box>
        }
        onClose={onClose}
        dataHook={reviewReplyDataHooks.modal}
        footer={
          <ReplyModalFooter
            onCancel={onCancel}
            onSubmit={writeReplyToReviewAndUpdateReviews}
            isLoading={isInProcess}
          />
        }
      />
    </Modal>
  );
};

export default ReviewReplyModal;

const ReplyModalFooter: FC<{
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = ({ onSubmit, onCancel, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Box direction="vertical">
      <Divider />
      <Box align="space-between" verticalAlign="middle" padding="24px">
        <Button
          dataHook={reviewReplyDataHooks.cancelButton}
          onClick={onCancel}
          priority="secondary"
          size="large"
        >
          {t('marketplace.template-page.review-reply-modal.cta-cancel')}
        </Button>
        <Button
          dataHook={reviewReplyDataHooks.submitButton}
          onClick={onSubmit}
          size="large"
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader size="tiny" />
          ) : (
            t('marketplace.template-page.review-reply-modal.cta-submit')
          )}
        </Button>
      </Box>
    </Box>
  );
};
