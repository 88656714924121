import React, { Dispatch, FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { InputArea, InputAreaProps } from '@wix/design-system';
import { ReplyState, ReplyUtils } from '../useReplyToReview';

export const ReplyInputArea: FC<{
  replyState: Partial<ReplyState>;
  setState: ReplyUtils['setState'];
  inputAreaProps?: InputAreaProps;
  autoFocus?: boolean;
}> = ({
  replyState: { statusMessage, status, replyBody },
  setState,
  inputAreaProps,
  autoFocus,
}) => {
  const { t } = useTranslation();

  return (
    <InputArea
      dataHook="write-reply-review-textarea"
      rows={6}
      placeholder={t('profile-page-review-write-reply-placeholder')}
      onChange={setState}
      value={replyBody}
      status={status}
      statusMessage={statusMessage}
      resizable
      autoFocus={autoFocus}
      {...inputAreaProps}
    />
  );
};

export default ReplyInputArea;
