import React, { FC } from 'react';
import _ from 'lodash';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { TextButton } from '@wix/design-system';
import { ExternalLink } from '@wix/wix-ui-icons-common';
import { ReviewData } from '@common/types';

import s from './ReviewSubjectLink.module.scss';
import VerticalDivider from '@components/VerticalDivider';
import { ReviewProps } from '../Review';

const ReviewSubjectLink: FC<
  Pick<ReviewProps, 'handleBiGenericClick'> &
    Pick<ReviewData, 'id' | 'url'> & { urlCtaKey: string }
> = ({ id, url, handleBiGenericClick, urlCtaKey }) => {
  const { t } = useTranslation();

  return (
    <>
      <VerticalDivider />
      <TextButton
        onClick={() =>
          handleBiGenericClick?.({
            clickType: 'view review site',
            clickData: { msid_link: url, review_id: id },
          })
        }
        className={s.colorD20}
        dataHook="review-url"
        as="a"
        skin="dark"
        size="tiny"
        weight="thin"
        underline="always"
        href={url}
        suffixIcon={<ExternalLink />}
        target="_blank"
      >
        {t(urlCtaKey)}
      </TextButton>
    </>
  );
};

export default ReviewSubjectLink;
