import React, { FC } from 'react';
import _ from 'lodash';
import { Box } from '@wix/design-system';
import ReplyReview from '../ReplyReview';
import WriteReplyReview, {
  IDeleteReply,
  WriteReplyToReview,
} from '../WriteReplyReview/WriteReplyReview';
import { ReviewProps } from '../Review/Review';

export const reviewReplyDialogueDatahooks = {
  container: 'review-reply-dialogue-container',
};

export interface ReplyProps {
  isUsingReplyModal?: boolean;
  hasActionMenu?: boolean;
  writeReplyToReview: WriteReplyToReview;
  deleteReply?: IDeleteReply;
}

export type IReviewReplyDialogueProps = ReplyProps &
  Pick<
    ReviewProps,
    'review' | 'isMyProfile' | 'isMobileView' | 'handleBiGenericClick'
  > & { replyAuthorTitle: string };

const ReviewReplyDialogue: FC<IReviewReplyDialogueProps> = ({
  review,
  replyAuthorTitle,
  isMyProfile,
  writeReplyToReview,
  hasActionMenu,
  deleteReply,
  isMobileView,
  isUsingReplyModal,
  handleBiGenericClick,
}) => {
  const reply = review?.reply;

  if (isMyProfile) {
    return (
      <Box
        display="block"
        marginTop="SP4"
        dataHook={reviewReplyDialogueDatahooks.container}
      >
        <WriteReplyReview
          review={review}
          writeReplyToReview={writeReplyToReview}
          isMobileView={isMobileView}
          isUsingMobileModal={isUsingReplyModal}
          handleBiGenericClick={handleBiGenericClick}
          hasActionMenu={hasActionMenu}
          deleteReply={deleteReply}
        />
      </Box>
    );
  }

  return (
    <Box display="block" dataHook={reviewReplyDialogueDatahooks.container}>
      <ReplyReview reply={reply} replyTitleKey={replyAuthorTitle} />
    </Box>
  );
};

export default ReviewReplyDialogue;
