import React, { FC } from 'react';
import { Box, SkeletonGroup, SkeletonLine, Divider } from '@wix/design-system';
import s from './RenderLoadingReview.module.scss';

const RenderLoadingReview: FC<{ hideDivider?: boolean }> = ({
  hideDivider = false,
}) => {
  return (
    <Box width="100%" paddingTop="SP5" direction="vertical">
      <SkeletonGroup skin="light" className={s.SkeletonGroup}>
        <Box direction="vertical" gap="18px">
          <Box direction="vertical" gap="6px">
            <SkeletonLine width="16%" />
            <SkeletonLine width="30%" />
          </Box>
          <Box direction="vertical" gap="6px">
            <SkeletonLine width="100%" />
            <SkeletonLine width="92%" />
          </Box>
        </Box>
      </SkeletonGroup>
      {!hideDivider && (
        <Box display="block" paddingTop="SP4">
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default RenderLoadingReview;
