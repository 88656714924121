import React, { useState, useEffect, FC } from 'react';
import _ from 'lodash';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Text } from '@wix/design-system';
import { Service } from '@wix/ambassador-category-services/types';
import { IHandleBIGenericClick, ReviewData } from '@common/types';
import { MAX_CHARS_IN_REVIEW } from '@utils/constants';
import s from './Review.module.scss';

import ShowMoreText from '../../ShowMoreText';
import { ReviewsTextKeys } from '../Reviews';
import VerticalDivider from '@components/VerticalDivider';
import StarBarAndRating from './StarsRatingBar';
import ReviewSubjectLink from './ReviewSubjectLink';
import ReviewReplyDialogue from '../ReviewReplyDialogue';
import { ReplyProps } from '../ReviewReplyDialogue/ReviewReplyDialogue';
import ReviewActions from './ReviewActions';

export type ReviewProps = {
  review: ReviewData;
  services?: Service[];
  isMyProfile?: boolean;
  handleBiGenericClick?: IHandleBIGenericClick;
  i18nKeys: ReviewsTextKeys;
  isMobileView?: boolean;
  replyProps: ReplyProps;
  onEdit?: () => void;
  userReview?: ReviewData | null;
};

const Review: FC<ReviewProps> = ({
  review,
  isMyProfile,
  services,
  i18nKeys: { viewReviewSubjectCTA, replyAuthorTitle },
  handleBiGenericClick,
  isMobileView,
  replyProps,
  onEdit,
  userReview,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [serviceName, setServiceName] = useState<string>('');
  const [overallScore, setOverallScore] = useState<number>(0);

  useEffect(() => {
    if (services && review) {
      const service = services.find((item) => item?.id === review.serviceId);
      if (_.get(service, 'displayKey')) {
        setServiceName(t(`explore.services.${service?.displayKey}`));
      }
    }
    setOverallScore(review?.overallScore || review?.score?.average || 0);
  }, [services, review, t]);

  const showActionButtons = review.id === userReview?.id;

  if (!review) {
    return <></>;
  }

  return (
    <Box
      display="block"
      dataHook="review-container"
      overflow="hidden"
      padding="2px"
      maxWidth="828px"
      width="100%"
    >
      <Box align="space-between" width="100%">
        {!_.isNil(review?.overallScore || review?.score?.average) && (
          <StarBarAndRating overallScore={overallScore} />
        )}
        {showActionButtons && (
          <ReviewActions
            review={review}
            onEdit={onEdit}
            isMobileView={isMobileView}
          />
        )}
      </Box>
      <Box paddingBottom="SP2" width="100%" verticalAlign="middle">
        {review.clientName && (
          <>
            <Text
              size="tiny"
              weight="thin"
              secondary
              dataHook="review-client-name"
            >{`${review.clientName}`}</Text>
            <VerticalDivider />
          </>
        )}
        {review.date && (
          <>
            <Text size="tiny" weight="thin" secondary dataHook="review-date">
              {review.date &&
                Intl.DateTimeFormat(language, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }).format(new Date(review.date))}
            </Text>
          </>
        )}
        {review.url && (
          <ReviewSubjectLink
            id={review.id}
            url={review.url}
            handleBiGenericClick={handleBiGenericClick}
            urlCtaKey={viewReviewSubjectCTA}
          />
        )}
      </Box>
      <Box display="block">
        <ShowMoreText
          dataHookPrefix="review-"
          originalText={review?.text}
          maxChars={MAX_CHARS_IN_REVIEW}
          handleShowMoreClick={() =>
            handleBiGenericClick?.({ clickType: 'show more reviews' })
          }
        />
      </Box>
      {serviceName && (
        <Text
          className={s.servicesPadding}
          size="small"
          weight="thin"
          light
          secondary
          tagName="div"
          dataHook="review-service-name"
        >
          {t('profile-page-review-service-text', { serviceName })}
        </Text>
      )}
      <ReviewReplyDialogue
        review={review}
        replyAuthorTitle={replyAuthorTitle}
        isMyProfile={isMyProfile}
        isMobileView={isMobileView}
        handleBiGenericClick={handleBiGenericClick}
        {...replyProps}
      />
    </Box>
  );
};

export default Review;
