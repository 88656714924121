import { ReviewData } from '@common/types';
import { InputStatus } from '@wix/design-system';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { useCallback, useEffect, useReducer } from 'react';
import { WriteReplyToReview } from './WriteReplyReview';

export interface ReplyState {
  isInProcess: boolean;
  replyBody: string;
  status?: InputStatus;
  statusMessage: string;
}

export const replyStateInitial: ReplyState = {
  isInProcess: false,
  replyBody: '',
  status: undefined,
  statusMessage: '',
};

export type ReplyUtils = ReturnType<typeof useReplyToReview>;

const getValidationError = (text: string) => {
  if (text.length < 1) {
    return 'profile-page-review-write-reply-error-text-not-valid';
  }

  if (text.length > 1000) {
    return 'marketplace.review.reply.length-error';
  }
};

const useReplyToReview = ({
  writeReplyToReview,
  review,
  onClose,
}: {
  writeReplyToReview: WriteReplyToReview;
  review: ReviewData;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [state, setState] = useReducer(
    (current: ReplyState, update: Partial<ReplyState>) => ({
      ...current,
      ...update,
    }),
    { ...replyStateInitial, replyBody: review?.reply?.text ?? '' },
  );

  useEffect(() => {
    setState({ ...replyStateInitial, replyBody: review?.reply?.text ?? '' });
  }, [review]);

  const onChangeWithValidation: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    const text = e.target.value;
    const validationError = getValidationError(text);
    const isTextValid = !validationError;
    if (isTextValid) {
      setState({
        status: undefined,
        statusMessage: undefined,
        replyBody: e.target.value,
      });
    } else {
      setState({
        status: 'error',
        statusMessage: t(validationError),
        replyBody: e.target.value,
      });
    }
  };

  const writeReplyToReviewAndUpdateReviews = useCallback(async () => {
    const { replyBody } = state;

    const validationError = getValidationError(replyBody);
    const isTextValid = !validationError;

    setState({
      isInProcess: true,
      status: undefined,
    });

    if (isTextValid) {
      try {
        await writeReplyToReview(review.id!, replyBody, review.revision);
        onClose();
        setState({ isInProcess: false });
      } catch (e: any) {
        setState({
          isInProcess: false,
          status: 'error',
          statusMessage: t('profile-page-review-write-reply-error-server'),
        });
      }
    } else {
      setState({
        isInProcess: false,
        status: 'error',
        statusMessage: t(validationError),
      });
    }
  }, [state, review.id, review.revision, t, writeReplyToReview]);

  return {
    state,
    setState: onChangeWithValidation,
    writeReplyToReviewAndUpdateReviews,
  };
};

export default useReplyToReview;
