import { FC } from 'react';
import _ from 'lodash';
import { useTranslation } from '@wix/fe-essentials-standalone';
import {
  StarsRatingBar,
  starRatingBarValue,
  Box,
  Text,
} from '@wix/design-system';
import s from './StarsRating.module.scss';

const StarBarAndRating: FC<{
  overallScore: number;
  showNumericalRating?: boolean;
}> = ({ overallScore, showNumericalRating = true }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Box marginBottom="4px" verticalAlign="middle">
      <StarsRatingBar
        value={Math.round(overallScore) as starRatingBarValue}
        size="small"
        readOnly
      />
      {showNumericalRating ? (
        <Box marginLeft="SP1" marginBottom="3px">
          <Text size="tiny" weight="bold" className={s.colorD20}>
            {Math.round(overallScore)}
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};

export default StarBarAndRating;
