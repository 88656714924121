import { FC } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, Heading, Text } from '@wix/design-system';
import Star from '../../Images/Star';
import s from './ReviewsHeader.module.scss';
import { ReviewsProps } from '../Reviews';

const ReviewsHeader: FC<
  Pick<
    ReviewsProps,
    | 'marginBottom'
    | 'showReviewsCount'
    | 'isMobileView'
    | 'reviewsCount'
    | 'averageReviewRating'
  >
> = ({
  marginBottom,
  showReviewsCount,
  isMobileView,
  reviewsCount,
  averageReviewRating,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Box direction="vertical" marginBottom={marginBottom}>
      {showReviewsCount && (
        <Heading
          size={isMobileView ? 'medium' : 'large'}
          as="h2"
          dataHook="reviews-title"
        >
          {t('profile-page-reviews-section-title')}
          {reviewsCount > 0
            ? ` (${Intl.NumberFormat(language, {}).format(reviewsCount)})`
            : ''}
        </Heading>
      )}

      {reviewsCount > 0 && (
        <Box marginTop="SP3">
          <Box verticalAlign="middle" marginRight="SP1">
            <Star
              className={isMobileView ? s.reviewStarMobile : s.reviewStar}
            />
          </Box>
          <Box verticalAlign="bottom">
            <Heading size={isMobileView ? 'large' : 'extraLarge'} as="span">
              {Intl.NumberFormat(language, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }).format(averageReviewRating)}
            </Heading>
            <Box paddingBottom={isMobileView ? '0' : '4px'}>
              <Text className={s.textColor}>&nbsp;/&nbsp;5</Text>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReviewsHeader;
