import React, { ReactNode, createContext, useContext } from 'react';
import { sessionIdStorage } from '@services/sessionService';

interface BiSessionContextProviderProps {
  children?: ReactNode;
}

type State = {
  sessionId?: string | undefined;
};

const BiSessionContext = createContext<State | null>(null);

export function BiSessionContextProvider(props: BiSessionContextProviderProps) {
  return (
    <BiSessionContext.Provider
      value={{
        sessionId: sessionIdStorage(),
      }}
    >
      {props.children}
    </BiSessionContext.Provider>
  );
}

export function useBiSessionContext() {
  const appContext = useContext(BiSessionContext);
  if (!appContext) {
    throw new Error(
      'useBiSessionContext must be used within the BiSessionContext.Provider',
    );
  }
  return appContext;
}
