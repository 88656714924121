import React, { FC, useState } from 'react';
import { useTranslation } from '@wix/fe-essentials-standalone';
import { Box, TextButton, PopoverMenu, IconButton } from '@wix/design-system';
import { ReviewData } from '@common/types';
import VerticalDivider from '@components/VerticalDivider';
import DeleteReviewModal from '../../DeleteReviewModal';
import { More } from '@wix/wix-ui-icons-common';

const ReviewActions: FC<{
  review: ReviewData;
  onEdit?: () => void;
  isMobileView?: boolean;
}> = ({ review, onEdit, isMobileView }) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const openDeleteConfirmationModal = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const showEditCta = !review?.reply;

  return (
    <Box gap="12px">
      {isMobileView ? (
        <PopoverMenu
          showArrow={false}
          placement="bottom-end"
          triggerElement={
            <IconButton priority="secondary">
              <More />
            </IconButton>
          }
        >
          {showEditCta && (
            <PopoverMenu.MenuItem
              text={t('marketplace.template-page.review.edit-mobile')}
              onClick={onEdit}
            />
          )}
          {showEditCta && <PopoverMenu.Divider />}
          <PopoverMenu.MenuItem
            text={t('marketplace.template-page.review.delete-mobile')}
            onClick={openDeleteConfirmationModal}
          />
        </PopoverMenu>
      ) : (
        <>
          {!review?.reply && (
            <>
              <TextButton size="small" onClick={onEdit}>
                {t('marketplace.template-page.review.edit')}
              </TextButton>
              <VerticalDivider />
            </>
          )}
          <TextButton size="small" onClick={openDeleteConfirmationModal}>
            {t('marketplace.template-page.review.delete')}
          </TextButton>
        </>
      )}
      <DeleteReviewModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        onModalClose={onModalClose}
        isMobileView={isMobileView}
        reviewId={review.id}
      />
    </Box>
  );
};

export default ReviewActions;
